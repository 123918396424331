import React, { FC } from 'react';
import styled from 'styled-components';
import BFLandingBannerArrow from 'assets/icons/bf-landing-banner-arrow.svg';
import BFLandingBannerPercent from 'assets/icons/bf-landing-banner-percent.svg';

import { Text } from 'components';
import { mobile, smLaptop, smMobile } from 'styles/breakpoints';

interface BlackFridayLandingLabelProps {
  text1: string;
  text2: string;
  text3: string;
  bgColor: string;
  text3color: string;
}

const Wrapper = styled.div`
  margin: 0.75rem 0rem 2rem 0rem;
  @media ${mobile} {
    margin: -1.25rem 0rem 2rem 0rem;
  }
`;

const ArrowContainer = styled.div`
  line-height: 0;
  margin-bottom: -2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Container = styled.div<{ bgColor: string }>`
  width: 100%;
  padding: 1rem;
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  border-radius: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${mobile} {
    padding: 0.5rem 0;
    height: 3rem;
  }
`;

const LeftArrow = styled(BFLandingBannerArrow)`
  margin-right: 1rem;

  @media ${mobile} {
    margin-right: 0.5rem;
  }
`;

const RightArrow = styled(BFLandingBannerArrow)`
  margin-left: 1rem;

  @media ${mobile} {
    margin-left: 0.5rem;
  }
`;

const LabelText = styled(Text)`
  margin-left: 0.5rem;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 130%;
  letter-spacing: -0.03rem;

  @media ${smLaptop} {
    font-size: 1rem;
  }
  @media ${smMobile} {
    font-size: 0.75rem;
  }
`;
const LabelText1 = styled.span`
  color: #fff;
  text-align: center;

  font-weight: 700;
`;
const LabelText2 = styled.span`
  color: #fff;
  font-weight: 400;
`;
const LabelText3 = styled.span<{ text3color: string }>`
  ${({ text3color }) =>
    text3color
      ? `color: ${text3color};`
      : `
    background: radial-gradient(
    71.77% 45.83% at 96.09% 40.38%,
    #edce82 0%,
    #c7a753 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  `}
  font-weight: 700;
`;

const ArrowWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const BlackFridayLandingLabel: FC<BlackFridayLandingLabelProps> = ({
  text1,
  text2,
  text3,
  bgColor = '#000000',
  text3color = '#ec5c3f',
}) => (
  <Wrapper>
    <ArrowContainer>
      <ArrowWrapper>
        <LeftArrow fill={bgColor} />
      </ArrowWrapper>
      <ArrowWrapper>
        <RightArrow fill={bgColor} />
      </ArrowWrapper>
    </ArrowContainer>
    <Container bgColor={bgColor}>
      <BFLandingBannerPercent />
      <LabelText>
        <LabelText1>{text1}</LabelText1>
        <LabelText2>{text2}</LabelText2>
        <LabelText3 text3color={text3color}>{text3}</LabelText3>
      </LabelText>
    </Container>
  </Wrapper>
);

export default BlackFridayLandingLabel;
