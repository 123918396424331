import React, { FC, Ref, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { LoadingPage, Seo } from 'components';
import { Analytics } from 'apis/Analytics';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import OrderSummary from './components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
// import LCFooter from 'components/LCFooter';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  width: 100%;
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');
  const user = useSelector((state: AppState) => state.user);

  const {
    selected_plans,
    upsell_products,
    quiz_answers,
    selected_plans_options,
  } = useSelector((state: AppState) => state.user);

  const allProducts = [...(selected_plans || []), ...(upsell_products || [])];

  const hasOnlySubscription =
    selected_plans?.length === 1 && selected_plans[0]?.is_subscription;

  const includesSubscription = allProducts?.filter(
    item => item.is_subscription && !item.key.includes('walking-yoga'),
  );

  const inlcludesCrossSell = allProducts?.find(item =>
    item.key.includes('walking-yoga'),
  );

  usePageView({
    country:
      user.geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user.user?.email.trim() || 'no-email-fallback',
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    client_code: code,
    zipCode: quiz_answers?.shipping_address?.postcode?.toLowerCase(),
    phoneNumber: quiz_answers?.shipping_address?.phoneNumber,
  });

  const LCRef = useRef(null);
  const WYRef = useRef(null);

  const handleScroll = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current !== null) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (!code) {
      return;
    }

    // Tracking.logEvent({
    //   event: 'email_left',
    //   client_code: code,
    // });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="left" />
      <Container>
        {code ? (
          <>
            {!hasOnlySubscription && <ThankYouSection {...data?.section1} />}
            {Boolean(includesSubscription?.length) && (
              <SectionContainer ref={LCRef}>
                <HowToSection {...data?.section2} />
              </SectionContainer>
            )}
            {Boolean(inlcludesCrossSell) && (
              <SectionContainer ref={WYRef}>
                <HowToSection {...data?.sectionWY} />
              </SectionContainer>
            )}
            <OrderSummary
              {...data?.section3}
              scrollToLC={() => handleScroll(LCRef)}
              scrollToWY={() => handleScroll(WYRef)}
            />
          </>
        ) : (
          <HowToSection {...data?.section2} />
        )}
      </Container>
    </>
  );
};

export default Success;
